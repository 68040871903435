import React from "react"
import * as styles from "./footer.module.scss"

const footer = () => (
  <footer className={styles.container}>
		<ul>
			<li><a href="mailto:domawsmith@gmail.com?subject=Portfolio">domawsmith@gmail.com</a></li>
			<li>Dom Smith &copy; {new Date().getFullYear()}</li>
		</ul>
	</footer>
)

export default footer
import React from "react"
import { Link } from "gatsby"
import * as styles from "./logo.module.scss"

class Logo extends React.Component  {
  _isMounted = false

  constructor(props) {
    super(props);
    this.state = {
      classes: [styles.container, 'undecorated']
    };
  }

  componentDidMount() {
    this._isMounted = true

    setTimeout(function() {
      if (this._isMounted) {
        this.setState({
          classes: [styles.container, 'undecorated', styles.expanded]
        })
      }
    }.bind(this), 100)

    setTimeout(function() {
      if (this._isMounted && (window.location.pathname === '/')) {
        this.setState({
          classes: [styles.container, 'undecorated']
        })
      }
    }.bind(this), 1200)

  }

  componentWillUnmount() {
    this._isMounted = false
  }

  render() {
    return (
      <Link to="/" className={this.state.classes.join(' ')} activeClassName={styles.active}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 600">
          <path d="M0,600V0H300C465,0,600,134.31,600,300S465,600,300,600Z"/>
          <ellipse fill="#fff" cx="300" cy="300" rx="199.17" ry="200"/>
          <polygon points="212.99 387.75 212.99 212.75 300.13 300.25 387.26 212.75 387.26 387.75 212.99 387.75"/>
        </svg>
      </Link>
    )
  }
}
export default Logo

import React from "react"
import * as styles from "./header.module.scss"
import Logo from "../components/logo"

const header = ({ title, description }) => {

  if ((typeof window !== 'undefined') && window.location.pathname === '/') {

    return (

      <header className={styles.container}>
        <Logo />
        <h1 className={styles.title}>{title}</h1>
        <h2 className={styles.description}>{description}</h2>
      </header>

    )

  } 
  
  return (

    <header className={styles.container}>
      <Logo />
      <div className={styles.title}>{title}</div>
      <div className={styles.description}>{description}</div>
    </header>

  )

}

export default header